<template>
    <BT-Blade-Items
        v-if="locationID != null"
        :headers="[
            { text: 'Location', value: 'locationID', navigation: 'locations', itemText: 'locationName' },
            { text: 'Product', value: 'productID', navigation: 'products', itemText: 'productName' },
            { text: 'On Request', value: 'onRequest', textFilter: 'toDisplayNumber' },
            { text: 'Currently To Fulfill', value: 'oldConsigned', textFilter: 'toDisplayNumber' },
            { text: 'To Fulfill', value: 'consigned', textFilter: 'toDisplayNumber' },
            { text: 'Currently In Stock', value: 'oldInStock', textFilter: 'toDisplayNumber' },
            { text: 'In Stock', value: 'inStock', textFilter: 'toDisplayNumber' },
            { text: 'On Order', value: 'onOrder', textFilter: 'toDisplayNumber' },
        ]"
        navigation="stock-tracked-items"
        :onPullSuccessAsync="pullItems"
        :params="{ locationID: locationID }"
        title="Stock Tracked Items Correct">
        <template v-slot:itemActions="{ item }">
            <v-btn small icon @click.stop="save(item)" :disabled="item.isSaved" :loading="item.isLoading">
                <v-icon small>mdi-content-save</v-icon>
            </v-btn>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Tracked-Item-List-Correct',
    data: function() {
        return {
            loadingMsg: null,
            locationID: null
        }
    },
    async mounted() {
        this.locationID = await this.$selectItem({
            navigation: 'locations',
            itemText: 'locationName',
            itemValue: 'id',
            required: true
        });
    },
    methods: {
        async pullItems(list) {
            var stockEntries = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.locationID });

            var rList = list.map(x => Object.assign({}, { ...x }, { isLoading: false, isSaved: false, oldInStock: x.inStock, inStock: 0, oldConsigned: x.consigned, consigned: 0 }));

            rList.forEach(tItem => {
                var entries = stockEntries.filter(f => f.productID == tItem.productID && f.locationID == tItem.locationID);
                entries.forEach(entry => {
                    tItem.inStock += entry.available;
                    tItem.inStock += entry.onHold;
                });
            })

            var consignments = await this.$BlitzIt.store.getAll('stock-consignments', { locationID: this.locationID, includeDetails: true });

            consignments.forEach(consignment => {
                consignment.consignmentOrderItems.forEach(oItem => {
                    var rItem = rList.find(z => z.productID == oItem.productID);
                    if (rItem != null) {
                        rItem.consigned += oItem.quantity;
                    }
                })

                consignment.consignmentEntryItems.forEach(eItem => {
                    var rrItem = rList.find(z => z.productID == eItem.productID);
                    if (rrItem != null) {
                        rrItem.inStock += eItem.quantity;
                    }
                })
            })

            return rList;
        },
        async save(item) {
            try {
                item.isLoading = true;
                var res = await this.$BlitzIt.store.patch('stock-tracked-items', item);
                item.rowVersion = res.rowVersion;
                item.isSaved = true;
            }
            finally {
                item.isLoading = false;
            }
        }
    }
}
</script>