var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.locationID != null)?_c('BT-Blade-Items',{attrs:{"headers":[
        { text: 'Location', value: 'locationID', navigation: 'locations', itemText: 'locationName' },
        { text: 'Product', value: 'productID', navigation: 'products', itemText: 'productName' },
        { text: 'On Request', value: 'onRequest', textFilter: 'toDisplayNumber' },
        { text: 'Currently To Fulfill', value: 'oldConsigned', textFilter: 'toDisplayNumber' },
        { text: 'To Fulfill', value: 'consigned', textFilter: 'toDisplayNumber' },
        { text: 'Currently In Stock', value: 'oldInStock', textFilter: 'toDisplayNumber' },
        { text: 'In Stock', value: 'inStock', textFilter: 'toDisplayNumber' },
        { text: 'On Order', value: 'onOrder', textFilter: 'toDisplayNumber' } ],"navigation":"stock-tracked-items","onPullSuccessAsync":_vm.pullItems,"params":{ locationID: _vm.locationID },"title":"Stock Tracked Items Correct"},scopedSlots:_vm._u([{key:"itemActions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","disabled":item.isSaved,"loading":item.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.save(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1)]}}],null,false,2345690049)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }